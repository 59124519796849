import { Component, OnInit } from '@angular/core';
import { CatalogService } from '../../catalog.service';

@Component({
  selector: 'mt-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['../../catalog.component.scss']
})
export class CountriesComponent implements OnInit {
  public countriesList: any[];
  public sortToLetter: string;

  constructor(
    private catalogService: CatalogService
  ) {}

  ngOnInit() {
    this.catalogService.getCountriesList({favorite: false})
      .subscribe((resp) => {
        this.countriesList = resp.data;
      }, (err) => {
        console.log(err);
      });
  }

  onLetterSelect(e) {
    this.sortToLetter = e;
  }
}
