import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CatalogModule } from './modules/catalog/catalog.module';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './modules/homepage/homepage.component';
import { FooterComponent } from './shared/partials/footer/footer.component';
import { NotFoundComponent } from './modules/errors/not-found/not-found.component';
import { ResetConfirmComponent } from './modules/reset-confirm/reset-confirm.component';
import { DataService } from './data.service';
import { AuthService } from './auth.service';
import { ValidationService } from './validation.service';
import { LocalizationService } from './localization.service';
import { PopularComponent } from './modules/homepage/popular/popular.component';
import { NgxScrollLockModule } from 'ngx-scroll-lock';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxSpinnerModule, NgxSpinnerService} from 'ngx-spinner';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { StaticPagesComponent } from './modules/static-pages/static-pages.component';


@NgModule({
    declarations: [
        AppComponent,
        HomepageComponent,
        NotFoundComponent,
        PopularComponent,
        ResetConfirmComponent,
        StaticPagesComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        CatalogModule,
        CoreModule,
        NgxScrollLockModule,
        NgxPageScrollCoreModule,
        NgxPageScrollModule,
        InfiniteScrollModule,
        NgxSpinnerModule,
        AccordionModule.forRoot()
    ],
    providers: [
        AuthService,
        DataService,
        ValidationService,
        LocalizationService,
        NgxSpinnerService
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
