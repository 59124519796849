import {Component, DoCheck, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {InitService} from "../../init.service";
import {filter} from "rxjs/operators";
import {MetaTagsService} from "../../core/services/meta-tags/meta-tags.service";

@Component({
  selector: 'mt-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit, DoCheck {
  currentPlug: string;
  catalogHeight: string;

  constructor(private route: ActivatedRoute,
              private initService: InitService,
              private router: Router,
              private metaTagsService: MetaTagsService) {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((s: NavigationEnd) => {
        console.log(this.router.url)
        this.initService.getPageInfo(this.router.url).subscribe(resp => {
          this.metaTagsService.onUpdateMetaTags(resp.metaTitle, resp.metaDescription, resp.hreflangs);
        });
      });
  }

  ngOnInit() {
    this.currentPlug = this.route.snapshot.url[1].path;
  }

  ngDoCheck() {
    this.catalogHeight = `calc(100vh - ${this.initService.footerHeight + 66}px)`;

  }
}
