import { Component, ContentChild, ContentChildren, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { AutocompleteContentDirective } from './autocomplete-content.directive';
import { OptionComponent } from './option/option.component';
import { switchMap } from 'rxjs/operators';
import { merge } from 'rxjs';

@Component({
  selector: 'mt-autocomplete',
  templateUrl: './autocomplete.component.html',
  exportAs: 'mtAutocomplete',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent {
  @ViewChild('root', { static: false }) rootTemplate: TemplateRef<any>;

  @ContentChild(AutocompleteContentDirective, { static: false })
  content: AutocompleteContentDirective;

  @ContentChildren(OptionComponent) options: QueryList<OptionComponent>;

  optionsClick() {
    return this.options.changes.pipe(
      switchMap(options => {
        const clicks$ = options.map(option => option.click$);
        return merge(...clicks$);
      })
    );
  }
}
