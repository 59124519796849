import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocalizationService } from '../../localization.service';

import { SLUGS_IDENTIFICATOR } from './catalog.constants';


@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  constructor(
    private http: HttpClient,
    private localization: LocalizationService
  ) {}

  private postRequest(url, body): Observable<any> {
    const lang = this.localization.getLanguage();
    return this.http.post(environment.urlAPI + url + '?locale=' + lang, body);
  }

  public getCountriesList(value: any): Observable<any> {

    return this.postRequest('/catalog/countries', value);
  }

  public getRegionsList(value): Observable<any> {

    return this.postRequest(`/catalog/regions`, value);
  }

  public getCitiesList(value): Observable<any> {

    return this.postRequest(`/catalog/cities`, value);
  }

  public getDistrictsList(value): Observable<any> {

    return this.postRequest(`/catalog/districts`, value);
  }

  public getParamsForRequest(slug: string): any {
    const slugId = slug.slice(-2);
    const currentObj = SLUGS_IDENTIFICATOR.find(i => i.id === slugId);
    if (!currentObj) {
      return {country: slug};
    }
    return {[currentObj.field]: slug};
  }

  public getTypeOfUrlSlug(slug: string): string {
    const slugId = slug.slice(-2);
    const currentObj = SLUGS_IDENTIFICATOR.find(i => i.id === slugId);
    if (!currentObj) {
      return 'country';
    }

    return currentObj.field;
  }
}
