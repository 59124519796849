export const SLUGS_IDENTIFICATOR = [
  {
    id: '-r',
    field: 'region'
  },
  {
    id: '-c',
    field: 'city'
  },
  {
    id: '-d',
    field: 'district'
  }
];

export const DEFAULT_LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
