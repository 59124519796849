import {
  Component,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  Input,
  HostListener,
  ViewChild,
  ElementRef
} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormArray} from '@angular/forms';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {defineLocale} from 'ngx-bootstrap/chronos';
import * as locales from 'ngx-bootstrap/locale';
import {Router} from '@angular/router';
import {DataService} from '../../data.service';
import {flag, name, countries} from 'country-emoji';
import {PlaceType} from '../model/PlaceType';
import {LocalizationService} from '../../localization.service';
import {ukLocale} from '../../shared/locales/ukLocale';

@Component({
  selector: 'mt-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges {
  @Output('searchStarted') searchStarted = new EventEmitter<any>();
  @Input('searchInitData') searchData: any = false;
  @Input('isSmall') isSmall = false;
  @Input('isLarge') isLarge = false;
  @ViewChild('dataSelect', {static: false}) dataSelect: ElementRef;

  lock = false;
  target = 'body';

  submitted = false;
  placesLoading = false;
  amountInputValue = '';
  searchForm: FormGroup;
  minDate: Date;
  bsConfigDate: any = {
    containerClass: 'theme-dark-blue shadow-block-lg',
    dateInputFormat: 'D MMMM',
    rangeInputFormat: 'D MMMM',
    showPreviousMonth: true,
    showWeekNumbers: false

  };
  activeSearchFieldDestination = false;
  activeSearchFieldCalendar = false;
  activeSearchFieldPeople = false;
  activeSearchFieldCitizenship = false;


  isLoading = false;
  autocompleteData = [];
  selectedCitizenship: object;
  countriesList: any = [];
  autocompleteStarted = false;
  valueExists = false;
  place: any = null;
  lastKey: string;
  adultsAmount = 1;
  childrenAmount = 0;

  options;
  optionsHotels;

  ngOnChanges() {
    // REFACTOR IT
    let countryName;
    let countryFlag;
    if (this.searchData) {
      countryName = name(this.searchData.c);
      countryFlag = flag(this.searchData.c);
    }
    this.ds.searchData.subscribe((data) => {
      if (data) {
        if (!this.valueExists) {
          this.valueExists = true;
          this.minDate = new Date();
          this.minDate.setDate(this.minDate.getDate());
          this.countriesLitsBuild(data.citizenship);
          this.buildForm();
          if (data.people.children) {
            const o: any = data.people.children;
            this.amountChildrenUpdate(o.amount);
          }
          this.place = {};
          Object.assign(this.place, data.place);
          const dObj: any = {};
          Object.assign(dObj, data);
          dObj.place = this.place.name;
          this.searchForm.patchValue(dObj);
          if (data.people.children) {
            const o: any = data.people.children;
            const formPeople: any = this.searchForm.controls.people;
            formPeople.controls.children.setControl('years', this.fb.array(o.years || []));
          }
          this.generateAmountInputValue();
        }
      } else {
        if (this.searchData && !this.valueExists) {
          this.isLoading = true;
          this.valueExists = true;
          this.minDate = new Date();
          this.minDate.setDate(this.minDate.getDate());
          const from = new Date(this.searchData.from);
          const to = new Date(this.searchData.to);
          this.setPlace(this.searchData.place);
          const obj = {
            place: this.searchData.place.name,
            date: [from, to],
            people: {
              adults: Number(this.searchData.adults),
              children: {}
            },
            citizenship: {
              name: countryName,
              flag: countryFlag,
              code: this.searchData.c
            }
          };
          if (this.searchData.children) {
            Object.assign(obj, {
              people: {
                adults: Number(this.searchData.adults),
                children: {
                  amount: this.searchData.children.split(',').length,
                  years: this.searchData.children.split(',')
                }
              }
            });
          }
          this.countriesLitsBuild(obj.citizenship);
          this.buildForm();
          if (this.searchData.children) {
            const o: any = obj.people.children;
            this.amountChildrenUpdate(o.amount);
          }

          this.searchForm.patchValue(obj);
          if (this.searchData.children) {
            const o: any = obj.people.children;
            const formPeople: any = this.searchForm.controls.people;
            formPeople.controls.children.setControl('years', this.fb.array(o.years || []));
          }
          this.generateAmountInputValue();
          this.isLoading = false;
        }
      }
    });
  }

  constructor(
    private ds: DataService,
    private fb: FormBuilder,
    private router: Router,
    private localeService: BsLocaleService,
    private localization: LocalizationService
  ) {
    const lang = this.localization.getLanguage();

    function defineLocales() {
      for (const locale in locales) {
        defineLocale(locales[locale].abbr, locales[locale]);
      }
      defineLocale('uk', ukLocale);
    }

    defineLocales();
    this.localeService.use(lang);
  }

  @HostListener('window:resize', [])
  onResize() {
    this.lock = false;
    this.activeSearchFieldDestination = false;
    this.activeSearchFieldCalendar = false;
    this.activeSearchFieldPeople = false;
    this.activeSearchFieldCitizenship = false;
  }

  countriesLitsBuild(selectedCountry?) {
    const all = Object.keys(countries).join(',').split(',');
    const countriesIgnoreList = [
      'AN',
      'AQ',
      'AS',
      'AX',
      'BV',
      'CC',
      'CK',
      'CX',
      'EH',
      'FK',
      'FM',
      'GS',
      'HM',
      'IM',
      'IO',
      'KP',
      'MP',
      'MS',
      'NC',
      'NF',
      'NU',
      'PM',
      'PN',
      'PS',
      'YT',
      'XK',
      'WF',
      'VG',
      'VI',
      'VA',
      'UM',
      'TK',
      'TF',
      'TC',
      'SZ',
      'SJ',
      'SH',
      'RE',
      'PS'
    ];
    for (const code of all) {
      if (countriesIgnoreList.includes(code)) {
        continue;
      }

      let correctName;

      if (code == 'CZ') {
        correctName = 'Czech Republic';
      }

      this.countriesList.push({
        name: correctName || name(code),
        // flag: flag(code),
        code
      });
      if (code === 'UA' && !selectedCountry) {
        this.selectedCitizenship = {
          name: name(code),
          // flag: flag(code),
          code
        };
      }
    }
    this.countriesList = this.countriesList.sort((a, b) => a.name.localeCompare(b.name));
    if (selectedCountry) {
      this.selectedCitizenship = selectedCountry;
    }
  }


  buildForm() {
    this.searchForm = this.fb.group({
      place: [null, Validators.required],
      date: [null, Validators.required],
      people: this.fb.group({
        adults: [2, [Validators.required, Validators.min(1), Validators.max(6)]],
        children: this.fb.group({
          amount: [0, [Validators.required, Validators.min(0), Validators.max(6)]],
          years: this.fb.array([])
        })
      }),
      citizenship: [null, Validators.required]
    });
    this.generateAmountInputValue();

  }

  ngOnInit() {
    this.ds.openCalendar.subscribe((data: any) => {
      if (data.type === 'place') {
        this.activeSearchFieldDestination = true;
        this.ds.autocomplete(data.data.name).subscribe((res) => {
          let place;
          if (data.data.type === 'c') {
            place = res.cities[0];
          } else if (data.data.type === 'r') {
            place = res.regions[0];
          }
          this.setPlace(place);
          this.searchForm.controls.place.setValue(place.name);
        });
      } else if (data.type === 'open') {
        this.activeSearchFieldCalendar = true;
        this.lock = true;
        this.dataSelect.nativeElement.click();
      }
    });

    if (!this.searchForm) {
      this.minDate = new Date();
      this.minDate.setDate(this.minDate.getDate());
      this.buildForm();
      this.countriesLitsBuild();
    }
  }

  generateAmountInputValue() {
    this.adultsAmount = this.searchForm.value.people.adults;
    this.childrenAmount = this.searchForm.value.people.children.amount;
  }

  autocompleteDataUpdate(val) {
    if (val.length > 0) {
      this.lastKey = val;
    } else {
      return;
    }
    if (!this.autocompleteStarted) {
      const that = this;
      this.autocompleteStarted = true;
      const str = val;
      setTimeout(() => {
        if (str.length > 0) {
          that.placesLoading = true;
          that.ds.autocomplete(str).subscribe((res) => {
            that.saveAutocompleteData(res);
            that.placesLoading = false;
            that.autocompleteStarted = false;
            if (str !== that.lastKey) {
              that.autocompleteDataUpdate(that.lastKey);
            }
          }, (err) => {
            that.clearAutocompleteData();
            that.placesLoading = false;
          });
        } else {
          this.clearAutocompleteData();
        }
      }, 50);
    }
  }

  clearAutocompleteData() {
    this.options = null;
    this.optionsHotels = null;
  }

  saveAutocompleteData(val) {
    const cities = val.cities;
    const districts = val.districts;
    const regions = val.regions;
    const countries = val.countries;
    // const customRegions = val.customRegions;
    const hotels = val.hotels;
    cities.forEach((part, index) => {
      cities[index].type = PlaceType.city;
    }, cities);
    districts.forEach((part, index) => {
      districts[index].type = PlaceType.district;
    }, districts);
    regions.forEach((part, index) => {
      regions[index].type = PlaceType.region;
    }, regions);
    countries.forEach((part, index) => {
      countries[index].type = PlaceType.country;
    }, countries);
    // customRegions.forEach((part, index) => {
    //   customRegions[index].type = PlaceType.customRegion;
    // }, customRegions);
    hotels.forEach((part, index) => {
      hotels[index].type = PlaceType.hotel;
    }, hotels);

    const opt = cities.concat(districts, regions, countries/*, customRegions*/);
    this.options = opt.sort((a, b) => {
      return b.hotelCount - a.hotelCount;
    }).filter((item) => item.hotelCount > 0);

    this.optionsHotels = val.hotels;

    this.autocompleteData = cities.concat(hotels);
  }

  setPlace(value) {
    this.place = value;
  }


  amountChildrenUpdate(value) {
    const formArrAmount = this.searchForm.value.people.children.years.length;
    const formPeople: any = this.searchForm.controls.people;
    const formArr = formPeople.controls.children.get('years') as FormArray;
    if (value >= 0) {
      if (value > formArrAmount) {
        if (value - formArrAmount > 1) {
          for (let i = 0; i < (value - formArrAmount); i++) {
            formArr.push(this.fb.control(10, Validators.required));
          }
        } else {
          formArr.push(this.fb.control(10, Validators.required));
        }

      } else {
        if ((formArrAmount - value) > 1) {
          for (let i = 0; i < (formArrAmount - value); i++) {
            formArr.removeAt((formArrAmount - 1) - i);
          }
        } else {
          formArr.removeAt(formArrAmount - 1);
        }
      }
    }
    this.generateAmountInputValue();
  }

  adultsAmountChange(status) {
    if (status) {
      if (this.searchForm.value.people.adults + 1 < 7) {
        this.searchForm.patchValue({
          people: {
            adults: this.searchForm.value.people.adults += 1
          }
        });
        this.generateAmountInputValue();
      }
    } else {
      if (this.searchForm.value.people.adults - 1 > 0) {
        this.searchForm.patchValue({
          people: {
            adults: this.searchForm.value.people.adults -= 1
          }
        });
        this.generateAmountInputValue();
      }
    }
  }

  childrenAmountChange(status) {
    if (status) {
      if (this.searchForm.value.people.children.amount + 1 < 7) {
        this.searchForm.patchValue({
          people: {
            children: {
              amount: this.searchForm.value.people.children.amount += 1
            }
          }
        });
        this.amountChildrenUpdate(this.searchForm.value.people.children.amount);
        this.generateAmountInputValue();
      }
    } else {
      if (this.searchForm.value.people.children.amount - 1 >= 0) {
        this.searchForm.patchValue({
          people: {
            children: {
              amount: this.searchForm.value.people.children.amount -= 1
            }

          }
        });
        this.amountChildrenUpdate(this.searchForm.value.people.children.amount);
        this.generateAmountInputValue();
      }
    }
  }


  onSubmit() {
    if (!this.submitted) {
      this.submitted = true;
      if (this.searchForm.valid && this.place) {
        this.isLoading = true;
        const form = this.searchForm.value;
        const place = {};

        Object.assign(place, this.place);
        const searchData = {
          citizenship: form.citizenship,
          date: form.date,
          people: form.people,
          place
        };
        // Object.assign(searchData, { place: place });
        this.ds.searchData.next(searchData);
        this.searchStarted.next(true);
        const lang = this.localization.getLanguage();
        const slug = this.localization.getDefaultBeLanguage() === lang ? this.place.slug : this.place.translations[lang].slug;
        const urlModule = this.place.type === 'hotel' ? 'hotel' : 'hotels';
        this.router.navigateByUrl('/' + lang + '/' + urlModule + '/' + slug);

        this.submitted = false;
        this.isLoading = false;
      } else {
        this.submitted = false;
      }
    }
  }

}
