import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { LocalizationService } from './localization.service';
import { STORAGE_KEYS } from './core/constants/storage-keys.constant';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public searchData = new BehaviorSubject<any>(false);
  public user = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private localization: LocalizationService,
    private router: Router
  ) {}

  private _postRequest(url, body): Observable<any> {
    return this.http.post(environment.urlAPI + url, body);
  }

  public setAuthToken(token: string): void {
    localStorage.setItem(STORAGE_KEYS.AUTH_TOKEN, token);
  }

  public getAuthToken(): string {
    return localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN);
  }

  public removeAuthToken(): void {
    localStorage.removeItem(STORAGE_KEYS.AUTH_TOKEN);
  }

  public setRefreshToken(token: string): void {
    localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, token);
  }

  public getRefreshToken(): string {
    return localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  }

  public removeRefreshToken(): void {
    localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
  }

  refreshTokenRequest(data): Observable<any> {
    return this._postRequest('/token/refresh', data).pipe(
      tap((resp) => {
        this.setAuthToken(resp.token);
        this.setRefreshToken(resp.refreshToken);
      })
    );
  }

  login(loginData: any): Observable<any> {
    return this._postRequest('/token/create', loginData);
  }

  registration(regData: any): Observable<any> {
    return this._postRequest('/users/register', regData);
  }

  getUserInfo(): Observable<any> {
    return this._postRequest('/users/info', {});
  }

  logOut(): void {
    this.removeAuthToken();
    this.removeRefreshToken();
    this.user.next(null);
    this.router.navigate(['/']);
  }

  changeProfileInfo(data: any): Observable<any> {
    return this._postRequest('/users/profile', data);
  }

  changePassword(data: any): Observable<any> {
    return this._postRequest('/users/change-password', data);
  }

  requestPasswordRestore(data: any): Observable<any> {
    const locale = this.localization.getLanguage();
    return this._postRequest(`/users/request-reset-password?locale=${locale}`, data);
  }

  resetPassword(data: any): Observable<any> {
    return  this._postRequest(`/users/reset-password`, data);
  }
}
