import { Injectable } from '@angular/core';
import { code, name } from 'country-emoji';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  isValidParams(params, withoutPlace = true, withoutC?): boolean {
    if ((params.city || params.hotel || params.region || params.district || params.country || params.customRegion || withoutPlace) && params.from && params.to && params.adults && (params.c || withoutC)) {
      if ((Number(params.city) !== NaN && Number(params.hotel) !== NaN && Number(params.region) !== NaN && Number(params.district) !== NaN && Number(params.country) !== params.country && Number(params.customRegion) !== NaN) || withoutPlace) {
        if (params.from.length !== 10 || params.to.length !== 10) {
          return false;
        }

        const from = moment(new Date(params.from)).format('YYYY-MM-DD');
        const to = moment(new Date(params.to)).format('YYYY-MM-DD');

        if (params.from === from && params.to === to) {
          if (Number(params.adults) !== NaN && Number(params.adults) > 0 && Number(params.adults) <= 10) {
            if (params.children) {
              const ages = params.children.split(',');
              if (ages.length > 0) {
                for (let age of ages) {
                  if (!(Number(age) !== NaN && Number(age) > 0 && Number(age) < 18)) {
                    return false;
                  }
                }
              } else {
                return false;
              }
            }
            if (code(name(params.c)) === params.c.toUpperCase()) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }
}
