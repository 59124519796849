import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';

// Libraries
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authToken = this.authService.getAuthToken();

    if (authToken) {
      request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${authToken}`) });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    if (!request.headers.has('Accept')) {
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    }

    return next.handle(request).pipe(
      catchError(err => {
        if (!this.isRefreshRequest(request.url) && (err.status === 401 || err.status === 403)) {
          // Try to refresh token if 401 or 403 response returned from api
          return this.refreshToken()
            .pipe(
              switchMap(() => {
                const newAuthToken = this.authService.getAuthToken();

                if (authToken) {
                  request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${newAuthToken}`) });
                }
                return next.handle(request);
              }),
              catchError((e) => {
                this.authService.logOut();
                return throwError(e);
              })
            );
        }

        const error = err.error.message || err.error || err.statusText;
        return throwError(error);
      })
    );
  }

  refreshToken() {
    const refreshToken = this.authService.getRefreshToken();
    if (refreshToken) {
      return this.authService.refreshTokenRequest({refreshToken}).pipe(
        tap((resp) => {
          console.log('Token refreshed: ', resp);
        })
      );
    } else {
      return throwError(null);
    }
  }

  private isRefreshRequest(requestUrl: string): boolean {
    if (!requestUrl) {
      return false;
    }
    const REFRESH_URL_KEY = 'token/refresh';
    return requestUrl.includes(REFRESH_URL_KEY);
  }
}
