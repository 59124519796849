import {Component, OnInit, OnChanges, Input, SimpleChanges, EventEmitter, Output} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { DEFAULT_LETTERS } from '../../catalog.constants';

@Component({
  selector: 'mt-catalog-filter',
  templateUrl: './catalog-filter.component.html',
  styleUrls: ['./catalog-filter.component.scss']
})
export class CatalogFilterComponent implements OnInit, OnChanges {
  @Input() dataList: any[];
  @Output() onLetterSelect = new EventEmitter<any>();

  public lettersList: string;
  public lettersArray: any[];
  public secondaryLetters: any[];

  constructor(
    private translate: TranslateService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.dataList) {
      this.getLetterList();
    }
  }

  getLetterList() {
    this.translate.get('CATALOG.LETTERS_LIST', {value: 'world'})
      .subscribe((resp: string) => {
        this.lettersList = resp;

        if (this.lettersList !== DEFAULT_LETTERS) {
          this.secondaryLetters = [...DEFAULT_LETTERS].map(l => {
            return {value: l};
          });
          this.secondaryLetters = this.checkIfLetterActive(this.secondaryLetters);
        }

        this.lettersArray = [...this.lettersList].map(l => {
          return {value: l};
        });
        this.lettersArray = this.checkIfLetterActive(this.lettersArray);
      });
  }

  checkIfLetterActive(arr) {
    const firstLetters = this.dataList.map(l => l.name.charAt(0).toLowerCase());
    const uniqLetters = [...new Set(firstLetters)];
    arr.forEach(item => {
      item.active = uniqLetters.includes(item.value.toLowerCase());
    });

    return arr;
  }

  selectLetter(letter) {
    if (letter.active) {
      this.onLetterSelect.emit(letter.value);
    }
  }
}
