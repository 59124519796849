import { Component, OnInit } from '@angular/core';
import { CatalogService } from '../../catalog.service';
import { LocalizationService } from '../../../../localization.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mt-regions',
  templateUrl: './regions.component.html',
  // styleUrls: ['.././catalog.component.scss']
})
export class RegionsComponent implements OnInit {
  public regionsList: any[];
  public currentCountry: string;
  public currentLocationName: string;
  public sortToLetter: string;

  constructor(
    private catalogService: CatalogService,
    private localization: LocalizationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.currentCountry = this.getCountryFromParams();
    this.getRegionsList(this.currentCountry);
  }

  getCountryFromParams(): string {
    return this.route.snapshot.params.slug;
  }

  getRegionsList(slug: string) {
    const params = this.catalogService.getParamsForRequest(slug);

    this.catalogService.getRegionsList(params)
      .subscribe((resp) => {
        this.regionsList = resp.data;
        this.currentLocationName = this.getCurrentLocationName(this.currentCountry, this.regionsList);
      }, (err) => {
        console.log(err);
      });
  }

  getCurrentLocationName(slug, dataList) {
    const type = this.catalogService.getTypeOfUrlSlug(slug);
    const dataOdj = dataList.find((pl) => pl[type]);

    return dataOdj[type];
  }

  onLetterSelect(e) {
    this.sortToLetter = e;
  }
}
