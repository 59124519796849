import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {InitService} from "../../../init.service";

@Component({
  selector: 'mt-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {
  @ViewChild('footer', {static: false}) elementView: ElementRef;

  constructor(private initService: InitService) {
  }

  ngOnInit() {
    this.initService.footerMenu.subscribe(resp => {
      this.initService.footerMenuData = resp;
    });
  }

  ngAfterViewInit() {
    this.initService.footerHeight = this.elementView.nativeElement.offsetHeight;
  }
}
