import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {


  constructor() {
  }

  public filterByPrice(hotels, value) {
    return hotels.filter((item) => {
      const firstOffer = item.o ? item.o[0] : [];
      if (!firstOffer) {
        return false;
      }
      const offerPrice = firstOffer.p;
      return Number(offerPrice) < value.price.max && Number(offerPrice) > value.price.min;
    });
  }

  public filterByFacilities(hotels, value) {
    return hotels.filter((item) => {
      const count = [];
      for (const checkbox of value.checkboxes) {
        for (const facility of item.f) {
          if (checkbox === facility) {
            if (count.indexOf(facility) === -1) {
              count.push(facility);
            }
          }
        }
      }
      if (count.length === value.checkboxes.length) {
        return true;
      }
      return false;
    });
  }

  public filterByFood(hotels, value) {
    hotels.forEach((hotel) => {
      hotel.o = hotel.o.filter((offer) => {
        return value.food.includes(offer.m);
      });
    });

    return hotels.filter(hotel => hotel.o.length > 0);
  }

  public filterByRef(hotels, value) {
    hotels.forEach((hotel) => {
      hotel.o = hotel.o.filter((offer) => {
        let status = 'ref';

        if (offer.refU === null || (offer.refU === true && offer.cdU === null)) {
          status = 'check';
        }

        if (offer.refU === false) {
          status = 'noref';
        }
        return value.ref.includes(status);
      });
    });

    return hotels.filter(hotel => hotel.o.length > 0);
  }

  public filterByType(hotels, value) {
    return hotels.filter((item) => {
      if (item.ht && item.ht.id) {
        return value.types.includes(item.ht.id);
      } else {
        return true;
      }
    });
  }

  public filterByStars(hotels, value) {
    return hotels.filter((item) =>  value.stars.map((e) => e.id).indexOf(item.s) !== -1);
  }

  public filterByRegions(hotels, value) {
    return hotels.filter((item) => value.regions.includes(item.rg))
  }

  public filterByCity(hotels, value) {
    return hotels.filter((item) => value.cities.includes(item.ct));
  }

  public filterByDistricts(hotels, value) {
    return hotels.filter((item) => value.districts.includes(item.dis))
  }
}
