import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationService } from '../../localization.service';

@Component({
  selector: 'mt-reset-confirm',
  templateUrl: './reset-confirm.component.html',
  styleUrls: ['./reset-confirm.component.scss']
})
export class ResetConfirmComponent implements OnInit {
  public resetToken: any;
  public resetForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private auth: AuthService,
    private toast: ToastrService,
    private translate: TranslateService,
    private localization: LocalizationService
  ) { }

  ngOnInit() {
    this.translate.setDefaultLang(this.localization.getDefaultLanguage());
    this.resetToken = this.route.snapshot.params.token;
    this.buildResetForm();

    if (!this.resetToken) {
      this.router.navigateByUrl('/');
    }
  }

  buildResetForm() {
    this.resetForm = this.fb.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    }, {
      validator: this.passwordMathValidator
    });
  }

  onResetConfirm() {
    const passwordData = {
      token: this.resetToken,
      password: this.resetForm.value.newPassword
    };
    this.auth.resetPassword(passwordData)
      .subscribe((resp) => {
        this.toast.success('Password reset');
        this.router.navigateByUrl('/');
      }, (err) => {
        console.log(err);
        this.toast.error('Error. Password reset failed.');
      });
  }

  passwordMathValidator(f: FormGroup) {
    return f.controls.newPassword.value === f.controls.confirmPassword.value ? null : { mismatch: true };
  }

}
