import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Observable, Subject} from 'rxjs';
import {LocalizationService} from './localization.service';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  mainMenu = new Subject();
  footerMenu = new Subject();
  mainMenuData;
  footerMenuData;
  footerHeight;

  constructor(
    private http: HttpClient,
    private localization: LocalizationService
  ) {
  }

  private postRequest(url, body): Observable<any> {
    return this.http.post(environment.urlAPI + url, body);
  }

  getInitData() {
    return this.postRequest('/init', {
      locale: this.localization.getLanguage()
    });
  }

  getPageInfo(urlAddress) {
    return this.postRequest('/page-info?locale=' + this.localization.getLanguage(), {
      url: environment.url + urlAddress
    });
  }
}
