import {Component, Inject, OnInit} from '@angular/core';

import { DataService } from '../../../data.service';
@Component({
  selector: 'mt-popular',
  templateUrl: './popular.component.html',
  styleUrls: ['./popular.component.scss']
})
export class PopularComponent implements OnInit {
  public countries = [];
  public cities = [];

  constructor(
    private ds: DataService
  ) { }

  ngOnInit() {
    this.ds.getPopularDestination().subscribe(
      (resp) => {
        this.cities = resp.cities;
        this.countries = resp.countries;
      }, (err) => {
        console.log(err);
      }
    );
  }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}
