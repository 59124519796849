import { Component, OnInit, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'mt-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

  @Input('rate') rate: number = 1;
  @Input('huge') isHuge: boolean = false;

  ngOnChanges() {
    if (this.rate > 5) {
      this.rate = 5;
    }
    // if (this.rate < 1) {
    //   this.rate = 1;
    // }
  }

  constructor() { }

  ngOnInit() {
    
  }

}
