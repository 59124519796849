import { ruLocale } from 'ngx-bootstrap/locale';

let ukLocale = ruLocale;

const weeks = ["нд", "пн", "вт", "ср", "чт", "пт", "сб"];
const monthsRegex = /^(січ(ень|ня)|січ\.?|лют(ий|ого)|лют?\.?|берез(ень|ня)?|берез\.?|квіт(ень|ня)|квіт\.?|трав(ень|ня)|трав\.?|черв(ень|ня)|черв\.?|лип(ень|ня)|лип\.?|серп(ень|ня)?|серп\.?|верес(ень|ня)|верес?\.?|жовт(ень|ня)|жовт\.?|листопа(д|ду)|листоп?\.?|груд(ень|ня)|груд\.?)/i;
const monthsStrictRegex = /^(січ(ень|ня)|лют(ий|ого)|берез(ень|ня)|квіт(ень|ня)|трав(ень|ня)|черв(ень|ня)|лип(ень|ня)|серп(ень|ня)|верес(ень|ня)|жовт(ень|ня)|листопа(д|ду)|груд(ень|ня)|груд\.?)/i;
const monthsShortStrictRegex = /^(січ\.?|лют?\.?|берез\.?|квіт\.?|трав\.?|черв\.?|лип\.?|серп\.?|верес?\.?|жовт\.?|листоп?\.?|груд\.?)/i;
const months = ["січня", "лютого", "березня", "квітня", "травня", "червня", "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"];
const monthsShort = ["січ.", "лют.", "берез.", "квіт.", "трав.", "черв.", "лип.", "серп.", "верес.", "жовт.", "листоп.", "груд."];
const monthsParse = [/^січ/i, /^лют/i, /^берез/i, /^квіт/i, /^трав/i, /^черв/i, /^лип/i, /^серп/i, /^верес/i, /^жовт/i, /^листоп/i, /^груд/i];
const obj = {
  months: {
    format: months,
    standalone: months
  },
  monthsShort: {
    format: monthsShort,
    standalone: monthsShort
  },
  weekdays: {
    standalone: ["неділя", "понеділок", "вівторок", "середа", "четвер", "п’ятниця", "субота"],
    format: ["неділю", "понеділок", "вівторок", "середу", "четвер", "п'ятницю", "суботу"],
    isFormat: /\[ ?[Вв] ?(?:минулої | наступну | цю)? ?\] ?dddd/
  },
  weekdaysShort: weeks,
  weekdaysMin: weeks,
  monthsParse: monthsParse,
  longMonthsParse: monthsParse,
  shortMothsParse: monthsParse,
  monthsRegex: monthsRegex,
  monthsShortRegex: monthsRegex,
  monthsStrictRegex: monthsStrictRegex,
  monthsShortStrictRegex: monthsShortStrictRegex,
  calendar: {
    sameDay: "[Сьогодні о] LT",
    nextDay: "[Завтра о] LT",
    lastDay: "[Вчора о] LT"
  },
  relativeTime: {
    future: "через %s",
    past: "%s назад",
    s: "кілька секунд",
    h: "час",
    d: "день",
    M: "місяць",
    y: "рік"
  },
  meridiemParse: /ночі|ранку|дня|вечора/i,
};

Object.assign(ukLocale, obj);
export { ukLocale };