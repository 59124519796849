import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { LocalizationService } from '../../localization.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import {InitService} from "../../init.service";
import {MetaTagsService} from "../../core/services/meta-tags/meta-tags.service";

@Component({
  selector: 'mt-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  constructor(
    private ds: DataService,
    private localization: LocalizationService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public initService: InitService,
    private metaTagsService: MetaTagsService,
    public route: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.translate.setDefaultLang(this.localization.getDefaultLanguage());
      let lang = this.localization.getLanguage();
      if (params.lg !== lang) {
        if (this.localization.setLanguage(params.lg)) {
          lang = params.lg;
        } else {
          this.router.navigate(['/' + lang]);
        }
      }
      this.translate.use(lang);
    });
  }

  ngOnInit() {
    this.initService.getPageInfo(this.router.url).subscribe(resp => {
      this.metaTagsService.onUpdateMetaTags(resp.metaTitle, resp.metaDescription, resp.hreflangs);
    });
  }

}
