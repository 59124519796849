export enum SearchType {
    hotel = 'hotel',
    geography = 'geography',
    customRegion = 'customRegion',
    point = 'point'
}

export interface HotelSearchRequest {
    citizenship: string,
    checkIn: string,
    checkOut: string,
    adults: number,
    noCache: boolean,
    async: boolean,
    type: SearchType,
    search: any,
    kids?: Array<number>
}