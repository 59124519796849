import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizationService } from '../../../../localization.service';

@Component({
  selector: 'mt-catalog-navigation',
  templateUrl: './catalog-navigation.component.html',
  styleUrls: ['./catalog-navigation.component.scss']
})
export class CatalogNavigationComponent implements OnInit {

  @Input() currentPage: string;

  constructor(
    private localization: LocalizationService,
    private router: Router
  ) {}

  ngOnInit() {
  }

  navigateToCatalogMain() {
    const lang = this.localization.getLanguage();
    this.router.navigate([lang, 'countries']);
  }

}
