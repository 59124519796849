import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'mt-filters-ref',
  templateUrl: './filters-ref.component.html',
  styleUrls: ['./filters-ref.component.scss']
})
export class FiltersRefComponent implements OnInit {
  @Input('amount') amount: any[];
  @Output('eventUpdate') eventUpdate = new EventEmitter<any>();

  isCollapsed: boolean = false;
  valueArr: any = [];
  labelsText = [
    'FILTER.REF.REF',
    'FILTER.REF.NOREF',
    'FILTER.REF.CHECK'
  ];

  constructor() {
  }

  ngOnInit() {
  }

  selectItem(item, name) {
    item.checked = !item.checked;
    item.name = name;
    this.valueArr.push(item);
    this.eventUpdate.emit({array: this.valueArr, updated: item});
  }
}
