import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'mt-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  public onClose: Subject<boolean>;
  public headingText: string;
  public mainText: string;
  public confirmText: string;
  public cancelText: string;
  public showCloseBtn: boolean;
  constructor(
    private modalRef: BsModalRef,
  ) {
  }

  ngOnInit() {
    this.onClose = new Subject<boolean>();
  }
  hideModal(confirmed: boolean = false) {
    this.onClose.next(confirmed);
    this.modalRef.hide();
  }
}

