import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {environment} from '../environments/environment';
import * as moment from 'moment';
import {HotelSearchRequest, SearchType} from './shared/model/HotelSearchRequest';
import {LocalizationService} from './localization.service';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  public searchData = new BehaviorSubject<any>(false);
  public openCalendar = new Subject();

  constructor(
    private http: HttpClient,
    private localization: LocalizationService
  ) {
  }

  resetData() {
    this.searchData.next(false);
  }

  private postRequest(url, body): Observable<any> {
    const lang = this.localization.getLanguage();
    return this.http.post(environment.urlAPI + url + '?locale=' + lang, body);
  }

  // old autocomplete
  // autocomplete(val): Observable<any> {
  //   const reqBody = {'string': val};
  //   return this.postRequest('/hotel/autocomplete', reqBody);
  // }

  autocomplete(val): Observable<any> {
    return this.http.post(environment.url + '/autocomplete.php', {string: val});
  }

  search(value): Observable<any> {

    const lang = this.localization.getLanguage();
    const slug = this.localization.getDefaultBeLanguage() === lang ? value.place.slug : value.place.translations[lang].slug;
    let reqBody: HotelSearchRequest = {
      citizenship: value.citizenship.code.toUpperCase(),
      checkIn: moment(new Date(value.date[0])).format('YYYY-MM-DD'),
      checkOut: moment(new Date(value.date[1])).format('YYYY-MM-DD'),
      adults: Number(value.people.adults),
      noCache: false,
      async: true,
      type: SearchType.geography,
      search: {
        slug // : value.place.slug ? value.place.slug : value.place
      }
    };

    if (value.people.children && (value.people.children.years && (value.people.children.years.length > 0))) {
      Object.assign(reqBody, {kids: value.people.children.years.map(x => +x)});
    }

    return this.postRequest('/hotel/search', reqBody);
  }

  searchNoParams(slug): Observable<any> {
    const reqBody = {
      "type": SearchType.geography,
      "search": {
        slug: slug
      }
    };
    return this.postRequest('/hotel/list', reqBody);
  }

  hotelList(slug: string): Observable<any> {
    const reqBody = {
      type: SearchType.geography,
      search: { slug }
    };
    return this.postRequest('/hotels/list', reqBody);
  }

  hotelListFilters(hotelIds: number[]): Observable<any> {
    return this.postRequest('/hotels/list-filters', { hotelIds });
  }

  searchResult(value): Observable<any> {
    let reqBody = {
      "searchId": value,
      "waitForFinish": false,
      "sort": {
        "type": "price",
        "direction": "asc"
      }
    };
    return this.postRequest('/hotel/search/result', reqBody);
  }


  searchInfo(searchId): Observable<any> {
    let reqBody = {
      "searchId": searchId
    };

    return this.postRequest('/hotel/search-info', reqBody);
  }


  hotelInfo(value): Observable<any> {
    let reqBody = {
      "slugs": [value]
    };
    return this.postRequest('/hotel/info', reqBody);
  }

  hotelInfoByIds(value): Observable<any> {
    let reqBody = {
      "hotelIds": [value]
    };
    return this.postRequest('/hotel/info', reqBody);
  }

  hotelOffers(value): Observable<any> {
    let reqBody = {
      "searchId": value.searchId,
      "waitForFinish": false,
      "slug": value.hotelSlug,
      "groupRooms": true
    };

    return this.postRequest('/hotel/offers', reqBody);
  }


  hotelActualization(offerId: number): Observable<any> {
    let reqBody = {
      "offerId": offerId
    };

    return this.postRequest('/hotel/actualization', reqBody);
  }

  hotelBookingForms(value): Observable<any> {
    let reqBody = {
      "supplier": value.supplier,
      "adults": value.adults
    };
    if (value.children) {
      Object.assign(reqBody, {"children": value.children});
    }
    return this.postRequest('/hotel/booking-form-fields', reqBody);
  }

  getPopularDestination(): Observable<any> {
    return this.postRequest('/popular-destinations', {});
  }
}
