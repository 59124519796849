import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomepageComponent} from './modules/homepage/homepage.component';
import {NotFoundComponent} from './modules/errors/not-found/not-found.component';
import {CountriesComponent} from './modules/catalog/components/countries/countries.component';
import {RegionsComponent} from './modules/catalog/components/regions/regions.component';
import {CitiesComponent} from './modules/catalog/components/cities/cities.component';
import {DistrictsComponent} from './modules/catalog/components/districts/districts.components';
import {ResetConfirmComponent} from './modules/reset-confirm/reset-confirm.component';
import {AuthGuard} from './core/guards/auth.guard';
import {StaticPagesComponent} from './modules/static-pages/static-pages.component';
import {CatalogComponent} from "./modules/catalog/catalog.component";


const routes: Routes = [
  {path: '', component: HomepageComponent},
  {path: ':lg', component: HomepageComponent},
  {
    path: ':lg/hotels/:slug',
    loadChildren: () => import('./modules/hotels-list/hotels-list.module').then(mod => mod.HotelsListModule)
  },
  {path: ':lg/hotel/:slug', loadChildren: () => import('./modules/hotel/hotel.module').then(mod => mod.HotelModule)},
  {path: ':lg/booking/:id', loadChildren: () => import('./modules/booking/booking.module').then(mod => mod.BookingModule)},
  {
    path: ':lg/profile',
    loadChildren: () => import('./modules/profile/profile.module').then(mod => mod.ProfileModule),
    canActivate: [AuthGuard]
  },
  {path: ':lg/countries', component: CatalogComponent},
  {path: ':lg/regions/:slug', component: CatalogComponent},
  {path: ':lg/cities/:slug', component: CatalogComponent},
  {path: ':lg/districts/:slug', component: CatalogComponent},
  {path: ':lg/reset-password/:token', component: ResetConfirmComponent},
  {path: ':lg/page/:slug', component: StaticPagesComponent},
  {path: 'not-found', component: NotFoundComponent},
  {path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
