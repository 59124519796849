import { Component, OnInit } from '@angular/core';
import { CatalogService } from '../../catalog.service';
import { LocalizationService } from '../../../../localization.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mt-districts',
  templateUrl: './districts.component.html',
  // styleUrls: ['.././catalog.component.scss']
})
export class DistrictsComponent implements OnInit {
  public districtsList: any[];
  public currentCity: string;
  public currentLocationName: string;
  public sortToLetter: string;

  constructor(
    private catalogService: CatalogService,
    private localization: LocalizationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.currentCity = this.getCityFromParams();
    this.getCitiesList(this.currentCity);
  }

  getCityFromParams(): string {
    return this.route.snapshot.params.slug;
  }

  getCitiesList(slug: string) {
    const params = this.catalogService.getParamsForRequest(slug);

    this.catalogService.getDistrictsList(params)
      .subscribe((resp) => {
        this.districtsList = resp.data;
        this.currentLocationName = this.getCurrentLocationName(this.currentCity, this.districtsList);
      }, (err) => {
        console.log(err);
      });
  }

  getCurrentLocationName(slug, dataList) {
    const type = this.catalogService.getTypeOfUrlSlug(slug);
    const dataOdj = dataList.find((pl) => pl[type]);

    return dataOdj[type];
  }

  onLetterSelect(e) {
    this.sortToLetter = e;
  }
}
