import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  constructor() { }

  private DEFAULT_LANGUAGE: string = 'uk';
  private DEFAULT_BE_LANGUAGE: string = 'en';
  private languages: Array<string> = [this.DEFAULT_LANGUAGE, 'ru', 'en'];


  setLanguage(lang) {
    if (this.languages.includes(lang)) {
      window.localStorage.setItem('language', lang);
      return true;
    }
    return false;
  }

  getLanguage() {
    const lang = window.localStorage.getItem('language');
    if (lang && this.languages.includes(lang)) {
      return lang;
    } else {
      return this.DEFAULT_LANGUAGE;
    }
  }

  getDefaultLanguage() {
    return this.DEFAULT_LANGUAGE;
  }

  getDefaultBeLanguage() {
    return this.DEFAULT_BE_LANGUAGE;
  }
}
