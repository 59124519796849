import {NgModule, Injector} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {RatingComponent} from './rating/rating.component';
import {SearchComponent} from './search/search.component';
import {
  BsDatepickerModule,
  ButtonsModule,
  ModalModule,
  AlertModule,
  BsDropdownModule,
  RatingModule,
  CollapseModule,
  TabsModule,
  TooltipModule,
  ProgressbarModule,
  TypeaheadModule,
  CarouselModule,
  PaginationModule
} from 'ngx-bootstrap';
import {Ng5SliderModule} from 'ng5-slider';
import {NgSelectModule} from '@ng-select/ng-select';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {LoadingComponent} from './loading/loading.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ToastrModule} from 'ngx-toastr';
import {AutocompleteModule} from './autocomplete/autocomplete.module';
import {OverlayModule} from '@angular/cdk/overlay';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RoundPipe} from './pipes/round.pipe';
import {InViewportModule} from '@thisissoon/angular-inviewport';
import {ScrollSpyModule} from '@thisissoon/angular-scrollspy';
import {NgImageSliderModule} from 'ng-image-slider';
import {FiltersRefComponent} from './filters-ref/filters-ref.component';
import {FiltersFoodComponent} from './filters-food/filters-food.component';
import {AuthDialogComponent} from './header/auth-dialog/auth-dialog.component';
import {RestorePasswordDialogComponent} from './header/restore-password-dialog/restore-password-dialog.component';
import {FooterComponent} from './partials/footer/footer.component';
import {NgxScrollLockModule} from 'ngx-scroll-lock';
import {RouterModule} from '@angular/router';
import {EllipsisModule} from 'ngx-ellipsis';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locales/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    NgSelectModule,
    HttpClientModule,
    NgxDatatableModule,
    OverlayModule,
    AutocompleteModule,
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    RatingModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    ProgressbarModule.forRoot(),
    TypeaheadModule.forRoot(),
    CarouselModule.forRoot(),
    PaginationModule.forRoot(),
    LeafletModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    InViewportModule,
    ScrollSpyModule.forRoot(),
    NgImageSliderModule,
    NgxScrollLockModule,
    RouterModule,
    EllipsisModule
  ],
  declarations: [
    SearchComponent,
    RatingComponent,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    FiltersRefComponent,
    FiltersFoodComponent,
    AuthDialogComponent,
    ConfirmDialogComponent,
    RestorePasswordDialogComponent,
    RoundPipe,
    BreadcrumbsComponent
  ],
  entryComponents: [
    AuthDialogComponent,
    RestorePasswordDialogComponent,
    ConfirmDialogComponent
  ],
  exports: [
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    BsDatepickerModule,
    ButtonsModule,
    ModalModule,
    AlertModule,
    BsDropdownModule,
    RatingModule,
    CollapseModule,
    TabsModule,
    TooltipModule,
    ProgressbarModule,
    TypeaheadModule,
    CarouselModule,
    PaginationModule,
    LeafletModule,
    NgSelectModule,
    NgxDatatableModule,
    ToastrModule,
    OverlayModule,
    AutocompleteModule,
    TranslateModule,
    InViewportModule,
    ScrollSpyModule,
    NgImageSliderModule,
    SearchComponent,
    RatingComponent,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    FiltersRefComponent,
    FiltersFoodComponent,
    AuthDialogComponent,
    ConfirmDialogComponent,
    RoundPipe,
    EllipsisModule,
    BreadcrumbsComponent
  ]
})
export class SharedModule {

}
