import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthService } from '../../../auth.service';

@Component({
  selector: 'mt-restore-password-dialog',
  templateUrl: './restore-password-dialog.component.html',
  styleUrls: ['./restore-password-dialog.component.scss']
})
export class RestorePasswordDialogComponent implements OnInit {

  public restoreForm: FormGroup;
  public user: any;

  constructor(
    private modalRef: BsModalRef,
    private fb: FormBuilder,
    private toast: ToastrService,
    private auth: AuthService,
  ) {
  }

  ngOnInit() {
    this.buildChangeForm();
  }

  buildChangeForm() {
    this.restoreForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  onSubmitRestore() {
    this.auth.requestPasswordRestore(this.restoreForm.value)
      .subscribe((resp) => {
        this.toast.success('Letter with restore link has been sent to your email', 'Success');
        this.hideModal();
      }, (err) => {
        console.log(err);
        this.toast.error('Error. Password change failed.');
      });
  }

  hideModal() {
    this.modalRef.hide();
  }
}

