import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import { LocalizationService } from '../../../../localization.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { DEFAULT_LETTERS } from '../../catalog.constants';

@Component({
  selector: 'mt-places-list',
  templateUrl: './places-list.component.html',
  styleUrls: ['./places-list.component.scss']
})
export class PlacesListComponent implements OnInit, OnChanges {
  @Input() dataList: any[];
  @Input() navigateTo: string;
  @Input() sortValue: string;

  public listAlphabetical: any[] = [];
  public lettersList: string;

  constructor(
    private localization: LocalizationService,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataList) {
      this.getLettersList();
    } else if (changes.sortValue) {
      this.scrollToLetter(changes.sortValue.currentValue);
    }
  }

  navigateToPlace(locationType, item) {
    const lang = this.localization.getLanguage();
    let pageLocation = locationType;

    if (pageLocation === 'regions' && !item.countRegions) {
      pageLocation = 'cities';
    }

    if (pageLocation === 'cities' && !item.countCities) {
      pageLocation = 'districts';
    }

    if (pageLocation === 'districts' && !item.countDistricts) {
      pageLocation = 'hotels';
    }

    this.router.navigate([lang, pageLocation, item.slug]);
  }

  editData() {
    if (this.dataList) {
      this.listAlphabetical = [];
      [...this.lettersList].forEach((l) => {
        this.listAlphabetical.push({
          letter: l,
          places: []
        });
      });

      this.listAlphabetical.forEach((item) => {
        let filtered = this.dataList.filter(pl => {
          return pl.name.charAt(0).toLowerCase() === item.letter.toLowerCase();
        });
        filtered = filtered.sort((a, b) => a.name.localeCompare(b.name));
        item.places.push(...filtered);
      });
    }
  }

  getLettersList() {
    this.translate.get('CATALOG.LETTERS_LIST', {value: 'world'})
      .subscribe((resp: string) => {
        this.lettersList = resp;
        if (this.lettersList !== DEFAULT_LETTERS) {
          this.lettersList += DEFAULT_LETTERS;
        }
        this.editData();
      });
  }

  scrollToLetter(letter: string) {
    document.querySelector('#catalog-item-' + letter)
      .scrollIntoView();
  }

}
