import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  Input,
  HostListener,
  Inject,
  AfterViewInit
} from '@angular/core';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {LocalizationService} from '../../localization.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {AuthService} from '../../auth.service';
import {Subscription} from 'rxjs';
import {DOCUMENT} from '@angular/common';

import {AuthDialogComponent} from './auth-dialog/auth-dialog.component';
import {InitService} from '../../init.service';

@Component({
  selector: 'mt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  modalRef: BsModalRef;
  user: any = null;
  $user: Subscription;
  activeLanguageSwitch = false;
  activeMenu = false;
  mainMenuData;
  @Input('isWide') isWide: boolean = false;
  @Input('showBackground') showBackground: boolean = false;
  @Input('isTransparent') isTransparent: boolean = false;
  @Input('isSolid') isSolid: boolean = false;


  constructor(
    private modalService: BsModalService,
    private localization: LocalizationService,
    private translate: TranslateService,
    private auth: AuthService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    public initService: InitService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.translate.setDefaultLang(this.localization.getDefaultLanguage());
    const lang = this.localization.getLanguage();
    this.translate.use(lang);


    this.auth.user.subscribe((user) => {
      this.user = user;
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20) {
      document.querySelector('header').classList.add('scrolled');
    } else {
      document.querySelector('header').classList.remove('scrolled');
    }
  }

  @HostListener('window:resize', [])
  onResize() {
    this.activeLanguageSwitch = false;
    this.activeMenu = false;
  }

  ngOnInit() {
    if (!this.user && this.auth.getAuthToken()) {
      this.auth.getUserInfo()
        .subscribe((resp) => {
          this.user = resp;
          this.auth.user.next(this.user);
        }, (err) => {
          this.auth.user.next(null);
        });
    }
    this.initService.mainMenu.subscribe(resp => {
      this.initService.mainMenuData = resp;
    });
  }

  openLoginModal() {
    this.modalRef = this.modalService.show(AuthDialogComponent);
  }

  toProfile() {
    const lang = this.localization.getLanguage();

    this.router.navigate([lang, 'profile']);
  }

  onOpenNewPage(menu) {
    const lang = this.localization.getLanguage();
    this.router.navigate([lang, 'page', menu.slug]);
  }

  toHomepage() {
    this.router.navigateByUrl('/');
  }

  onLogout() {
    this.auth.logOut();
  }

  ngOnDestroy() {
    if (this.$user) {
      this.$user.unsubscribe();
    }
  }
}
