import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatalogComponent } from './catalog.component';
import { CountriesComponent } from './components/countries/countries.component';
import { RegionsComponent } from './components/regions/regions.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { CitiesComponent } from './components/cities/cities.component';
import { DistrictsComponent } from './components/districts/districts.components';
import { CatalogFilterComponent } from './components/catalog-filter/catalog-filter.component';
import { PlacesListComponent } from './components/places-list/places-list.component';
import { CatalogNavigationComponent } from './components/catalog-navigation/catalog-navigation.component';

import { CatalogService } from './catalog.service';


@NgModule({
  declarations: [
    CatalogComponent,
    CountriesComponent,
    RegionsComponent,
    CitiesComponent,
    DistrictsComponent,
    CatalogFilterComponent,
    PlacesListComponent,
    CatalogNavigationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  providers: [
    CatalogService
  ]
})
export class CatalogModule {}
