import {Component, DoCheck, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {InitService} from "../../../init.service";

@Component({
  selector: 'mt-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, DoCheck {
  currentPlug: string;
  catalogHeight: string;

  constructor(private route: ActivatedRoute, private initService: InitService) {
  }

  ngOnInit() {
    this.currentPlug = this.route.snapshot.url[1].path;
  }

  ngDoCheck() {
    this.catalogHeight = `calc(100vh - ${this.initService.footerHeight + 65}px)`;
  }

  OnBackBtn() {
    window.history.back();
  }
}
