import {
  AfterContentChecked,
  Component
} from '@angular/core';
import {InitService} from '../../init.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {LocalizationService} from '../../localization.service';
import {MetaTagsService} from '../../core/services/meta-tags/meta-tags.service';

@Component({
  selector: 'mt-static-pages',
  templateUrl: './static-pages.component.html',
  styleUrls: ['./static-pages.component.scss']
})
export class StaticPagesComponent implements AfterContentChecked {

  contentHeight = '500';
  staticPageData;
  secondLevelMenu;
  firstLevelSlug;
  lang = this.localization.getLanguage();

  constructor(public initService: InitService,
              private router: Router,
              private localization: LocalizationService,
              private metaTagsService: MetaTagsService,
              public route: ActivatedRoute,
  ) {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((s: NavigationEnd) => {
        this.initService.getPageInfo(this.router.url).subscribe(resp => {
          this.metaTagsService.onUpdateMetaTags(resp.metaTitle, resp.metaDescription, resp.hreflangs);
          this.staticPageData = resp;
          this.getDataForStatic();
        }, () => {
          this.router.navigate(['/' + this.lang + '/not-found']);
        });
      });
  }

  getDataForStatic() {
    if (this.initService.mainMenuData != null) {
      const url = this.router.url.toString();
      this.firstLevelSlug = url.split('/')[3];
      this.initService.mainMenuData.forEach(data => {
        if (data.slug === url.split('/')[4]) {
          this.secondLevelMenu = data.children;
        } else if (data.slug === url.split('/')[3]) {
          this.secondLevelMenu = data.children;
        }
      });
    }
  }

  ngAfterContentChecked() {
    this.contentHeight = `calc(100vh - ${this.initService.footerHeight + 65}px)`;
  }
}
