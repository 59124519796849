import {Inject, Injectable, RendererFactory2, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {

  constructor(private meta: Meta, @Inject(DOCUMENT) private dom, private title: Title,
  ) {
  }

  onUpdateMetaTags(metaTitle, metaDescription, hreflangs) {
    if (metaTitle) {
      this.title.setTitle(metaTitle);
    }
    if (metaDescription) {
      this.meta.updateTag({name: 'description', content: metaDescription});
    }
    if (hreflangs) {
      hreflangs.forEach(data => {
        this.createCanonicalURL('alternate', data.href, data.hreflang);
      });
    }
    this.createCanonicalURL('canonical', this.dom.URL);
  }

  createCanonicalURL(type, url, lang?) {
    const [links]: HTMLLinkElement[] = this.dom.querySelectorAll(`[rel="canonical"]`);
    if (links) {
      this.dom.head.removeChild(links);
    }
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', type);
    this.dom.head.appendChild(link);
    if (type === 'alternate') {
      link.setAttribute('type', 'text/html');
      link.setAttribute('hreflang', lang);
    }
    link.setAttribute('href', url);
  }
}
