import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { BsModalService, TabsetComponent } from 'ngx-bootstrap';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthService } from '../../../auth.service';

import { RestorePasswordDialogComponent } from '../restore-password-dialog/restore-password-dialog.component';

@Component({
  selector: 'mt-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss']
})
export class AuthDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('authTabs', { static: false }) authTabs: TabsetComponent;

  public loginForm: FormGroup;
  public signUpForm: FormGroup;
  public restoreModal: BsModalRef;
  public defaultTabIndex = 0;
  public defaultEmail: string;

  constructor(
    private modalRef: BsModalRef,
    private fb: FormBuilder,
    private toast: ToastrService,
    private auth: AuthService,
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.buildLoginForm();
    this.buildSignUpForm();
  }

  ngAfterViewInit(): void {
    if (this.defaultTabIndex) {
      this.selectTab(this.defaultTabIndex);
    }

    if (this.defaultEmail && this.loginForm) {
      this.loginForm.patchValue({email: this.defaultEmail});
    }
  }

  buildLoginForm() {
    this.loginForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.required]]
    });
  }

  buildSignUpForm() {
    this.signUpForm = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required]],
      password: [null, Validators.required]
    });
  }

  onSubmitLogin() {
    this.auth.login(this.loginForm.value)
      .subscribe((resp) => {
        this.auth.setAuthToken(resp.token);
        this.auth.setRefreshToken(resp.refreshToken);
        this.getUserInfo();
      }, (err) => {
        console.log(err);
        this.toast.error('Error. Username or password is wrong');
      });
  }
  getUserInfo() {
    this.auth.getUserInfo().subscribe(
      (resp) => {
        this.auth.user.next(resp);
        this.hideModal();
      }, (err) => {
        this.toast.error('Error. C\'act get user info');
      }
    );
  }

  onSubmitSignUp() {
    const formValue = this.signUpForm.value;
    formValue.role = 'ROLE_USER';
    this.auth.registration(this.signUpForm.value)
      .subscribe((resp) => {
        this.auth.setAuthToken(resp.token);
        this.auth.setRefreshToken(resp.refreshToken);
        this.getUserInfo();
      }, (err) => {
        console.log(err);
      });
  }

  hideModal() {
    this.modalRef.hide();
  }

  selectTab(index: number) {
    this.authTabs.tabs[index].active = true;
  }

  onPasswordRestore() {
    this.restoreModal = this.modalService.show(RestorePasswordDialogComponent);
    this.hideModal();
  }
}

