import {Component, OnInit, Input} from '@angular/core';
import {BreadcrumbsItemInterface} from '../../core/interfaces/breadcrumbs-item.interface';

@Component({
  selector: 'mt-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() itemsList: BreadcrumbsItemInterface[];

  testItems: BreadcrumbsItemInterface[] = [
    {
      name: 'Main page',
      url: 'link 1'
    },
    {
      name: 'Search',
      url: 'link 2'
    },
    {
      name: 'Hotel name',
      url: 'link 3'
    },
    {
      name: 'Booking',
      url: 'link 4'
    }
  ];

  constructor() {}

  ngOnInit() {
    // ToDo: Remove this after backend task will be done
    this.itemsList = this.testItems;
  }

  navigateByLink(item: BreadcrumbsItemInterface, last: boolean): void {
    if (!last) {
      console.log(`Navigate by link: ${item.url}`);
    }
  }
}

